import type { GeekleInitialWizardFormData } from './GeekleInitialWizardSubmit';

import {
  ErrorCode,
  useRunOnceEffect,
  useApiMutation,
} from '@basaldev/blocks-frontend-framework';
import { api, NBError } from '@basaldev/blocks-frontend-sdk';

export interface UseSubmitWizard {
  onComplete: () => void;
  showToast: (message: string) => void;
  successMessage: string;
  userApi: Pick<api.UserApi, 'updateUser'>;
  userId?: string | null;
  wizardFormState: Partial<GeekleInitialWizardFormData>;
}

export const useSubmitWizard = ({
  onComplete,
  showToast,
  successMessage,
  userApi,
  userId,
  wizardFormState,
}: UseSubmitWizard) => {
  if (!userId) {
    throw new NBError({
      code: ErrorCode.internalServerError,
      message: 'User id could not be determined in wizard submit',
    });
  }

  const [handleUpdateUser, isUpdating] = useApiMutation<
    api.UserResponse,
    string,
    api.UpdateUserDto
  >(userApi.updateUser.bind(userApi), {
    onSuccess: onComplete,
    showToast,
    successMessage,
  });

  useRunOnceEffect(async () => {
    await handleUpdateUser(userId, {
      addressLine1: wizardFormState.prefecture,
      addressLine2: wizardFormState.cityTownVillage,
      addressLine3: wizardFormState.townStreetBuilding,
      customFields: {
        compensation_amount: wizardFormState.compensationAmount,
        compensation_type: wizardFormState.compensationType,
        is_signup_wizard_complete: true,
        katakana_name: wizardFormState.furigana,
        profession_category_names: wizardFormState.jobTypes ?? [],
        skills: Object.values(wizardFormState.skillGroups ?? {}).flat(),
        years_experience: wizardFormState.experience ?? {},
      },
      name: wizardFormState.personName,
      phoneNumber: wizardFormState.contactPhone,
      postalCode: wizardFormState.zipCode,
    });
  });

  return {
    isUpdating,
  };
};
