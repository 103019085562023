import {
  BasicUserInformationInputFormData,
  DesiredCompensationFormData,
  ExperienceInputFormData,
  JobTypeSelectionFormData,
  LoadingPage,
  SkillSelectionFormData,
  useTranslation,
  WizardPageProps,
} from '@basaldev/blocks-frontend-framework';
import { api } from '@basaldev/blocks-frontend-sdk';

import { useSubmitWizard } from './useSubmitWizard';

export type GeekleInitialWizardFormData = BasicUserInformationInputFormData &
  JobTypeSelectionFormData &
  ExperienceInputFormData &
  SkillSelectionFormData &
  DesiredCompensationFormData;

export interface GeekleInitialWizardSubmitOptions {
  userApi: Pick<api.UserApi, 'updateUser'>;
}

export const createGeekleInitialWizardSubmit = ({
  userApi,
}: GeekleInitialWizardSubmitOptions) => {
  const GeekleInitialWizardSubmit: React.FC<
    WizardPageProps<GeekleInitialWizardFormData>
  > = ({ blockProps, onSubmit, wizardFormState }) => {
    const { t } = useTranslation();

    useSubmitWizard({
      onComplete: () => onSubmit({}),
      showToast: blockProps.showToast,
      successMessage: t('Geekle:initialWizardSubmitSuccess'),
      userApi,
      userId: blockProps.sessionState.userId,
      wizardFormState,
    });

    return <LoadingPage screenMode={blockProps.screenMode} />;
  };

  return GeekleInitialWizardSubmit;
};
