import {
  createApp,
  createAppInitializer,
} from '@basaldev/blocks-frontend-framework';
import { api, session, socket } from '@basaldev/blocks-frontend-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '@basaldev/blocks-frontend-framework/dist/style.css';
import './core-styles.css';
import { SentryLogger } from './sentryLogger';
import { GeekleDemandAppTemplate } from './template';

const sessionService = new session.CookieSessionService();
const authApi = new api.AuthDefaultAdapterApi(
  '/api/demand-auth',
  sessionService
);
const catalogApi = new api.CatalogDefaultAdapterApi(
  '/api/catalog',
  sessionService
);
const orderApi = new api.OrderDefaultAdapterApi('/api/order', sessionService);
const chatApi = new api.MessagingChatAdapterApi('/api/chat', sessionService);
const organizationApi = new api.OrganizationDefaultAdapterApi(
  '/api/organization',
  sessionService
);
const userApi = new api.UserDefaultAdapterApi<{
  is_signup_wizard_complete: boolean;
}>('/api/demand-user', sessionService);

const chatSocketApi = new socket.MessagingChatSocketApi(
  '/api/chat',
  sessionService,
  {
    path: '/api/chat/socket.io',
    transports: ['polling'],
  }
);

const template = new GeekleDemandAppTemplate(
  {
    appName: 'Geekle Engineers',
    logger: new SentryLogger({
      appName: 'Geekle Engineers',
      env: 'production',
    }),
    pageTitleConfiguration: {
      appName: 'Geekle',
    },
  },
  {
    authApi,
    catalogApi,
    chatApi,
    chatSocketApi,
    orderApi,
    organizationApi,
    sessionService,
    userApi,
  }
);

const appInitializer = createAppInitializer({ template });
const App = createApp({ appInitializer, template });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
