import {
  Link,
  LoadingPage,
  createChangeEmail,
  createChangePassword,
  createDeactivateAccount,
  createDeactivateSuccessAccount,
  createDummy,
  createGroupedSettingsPage,
  createLandingFAQ,
  createLandingHero,
  createLandingHowToUse,
  createLandingMerits,
  createLandingSearchByAttributeItems,
  createListMessagesForTopicBlock,
  createListTopicsForUserBlock,
  createLogin,
  createLogout,
  createMyOrdersListPage,
  createMyPageJobActivity,
  createNavigation,
  createNewProductsList,
  createOrganizationDetailsPage,
  createOrganizationJobPositionsListPage,
  createProductDetailsPageForDemand,
  createProductFilter,
  createProductSearch,
  createRedirect,
  createResetPasswordRequest,
  createResetPasswordSubmit,
  createResetPasswordSuccess,
  createSections,
  createSideNavigationItemDefault,
  createSideNavigationItemExternalUrl,
  createSideNavigationItemUserInfo,
  createSignUp,
  createVerifyChangeEmailSuccess,
  createVerifyEmailSuccess,
  createWizard,
  createWizardAttributeItemsSelect,
  createWizardBaseInfo,
  createWizardCategoryExperience,
  createWizardCategorySelect,
  createWizardDesiredCompensation,
  defaultApiErrorHandler,
  defaultErrorMessageI18nHandler,
  Error500,
  initializeAutoRefreshToken,
  initializeServiceVersionCheckers,
  isEmailVerifiedRedirect,
  isLoggedIn,
  isNotLoggedIn,
  isSignupWizardCompletedRedirect,
  isSignupWizardNotCompletedRedirect,
  loadDefaultTranslations,
  loadTranslationsFromYaml,
  NavigationHeaderElement,
  NotFound404,
  Template,
  TemplateOpts,
  ToastsDisplay,
  UserCustomFields,
} from '@basaldev/blocks-frontend-framework';
import {
  api,
  log,
  session,
  socket,
  utils,
} from '@basaldev/blocks-frontend-sdk';
import merge from 'lodash.merge';
import partial from 'lodash.partial';

import {
  createGeekleInitialWizardSubmit,
  GeekleInitialWizardFormData,
} from './block/GeekleInitialWizardSubmit/GeekleInitialWizardSubmit';
import {
  getFAQItems,
  getHowToUseStepItems,
  getMeritItems,
} from './block/LandingPage/landingPage';
import logoUrl from './image/geekle.svg';
import heroImageUrl from './image/hero-image.png';
import translationOverrides from './translationOverrides.yaml';

interface GeekleDemandAppTemplateDependencies {
  /** Auth api client */
  authApi: Pick<
    api.AuthApi,
    | 'getOAuthGoogleLoginUrl'
    | 'login'
    | 'refresh'
    | 'logout'
    | 'onetimeTokenLogin'
    | 'getRequiredVersion'
    | 'ping'
  >;
  /** Catalog api client */
  catalogApi: Pick<
    api.CatalogApi,
    | 'getProduct'
    | 'listAttributes'
    | 'listCategories'
    | 'getRequiredVersion'
    | 'ping'
    | 'listProductsForActiveStatus'
  >;
  /** Chat api client */
  chatApi: Pick<
    api.ChatApi,
    | 'listTopicsSubscribedByUser'
    | 'getTopic'
    | 'listMessagesForTopic'
    | 'updateMessageReadStatus'
    | 'createMessage'
    | 'ping'
    | 'getRequiredVersion'
  >;
  /** Websocket api client */
  chatSocketApi: Pick<
    socket.ChatSocketApi,
    'subscribeToUserId' | 'unsubscribeToUserId'
  >;
  /** Order api client */
  orderApi: Pick<
    api.OrderApi,
    'createOrder' | 'listOrdersForUsers' | 'ping' | 'getRequiredVersion'
  >;
  /** Organization api client */
  organizationApi: Pick<
    api.OrganizationApi,
    'getOrganization' | 'ping' | 'getRequiredVersion'
  >;
  /** Session service for managing user sessions */
  sessionService: session.SessionService;
  /** User data api client */
  userApi: Pick<
    api.UserApi<UserCustomFields>,
    | 'createUser'
    | 'updateUser'
    | 'getUser'
    | 'resetPassword'
    | 'changePassword'
    | 'sendResetPasswordEmail'
    | 'sendVerificationEmail'
    | 'verifyEmail'
    | 'changeEmail'
    | 'verifyChangeEmail'
    | 'deactivateUser'
    | 'getRequiredVersion'
    | 'ping'
  >;
}

export class GeekleDemandAppTemplate implements Template {
  opts: Required<TemplateOpts>;
  dependencies: GeekleDemandAppTemplateDependencies;

  constructor(
    opts: TemplateOpts,
    dependencies: GeekleDemandAppTemplateDependencies
  ) {
    const domain = location.host.replace(/^.+?\./, '');
    this.opts = merge<Required<TemplateOpts>, TemplateOpts>(
      {
        apiErrorHandler: partial(defaultApiErrorHandler, dependencies.authApi),
        appInitialization: [
          partial(initializeServiceVersionCheckers, [
            dependencies.authApi,
            dependencies.catalogApi,
            dependencies.chatApi,
            dependencies.organizationApi,
            dependencies.userApi,
          ]),
          partial(initializeAutoRefreshToken, dependencies.authApi, {
            refreshIntervalMs: 5 * 60 * 1000,
          }),
        ],
        appInitializationLoader: LoadingPage,
        appName: 'geekle-demand-app',
        blockPages: [
          {
            component: createRedirect({
              options: { replace: true },
              to: 'landing.index',
            }),
            name: 'top',
            path: '/',
          },
          {
            component: createSections({
              components: [
                createMyPageJobActivity({
                  orderListRoute: 'job-applications.list',
                }),
                createNewProductsList({
                  catalogApi: dependencies.catalogApi,
                  numberOfProducts: 5,
                  productRoute: 'positions.show',
                  showSubtitle: false,
                  titleAlignment: 'start',
                }),
              ],
            }),
            name: 'landing.mypage',
            pageTitle: (t) => t('Landing:pageTitle'),
            path: '/mypage',
            validators: {
              emailVerified: isEmailVerifiedRedirect({
                notVerifiedRedirect: 'auth.login',
                userApi: dependencies.userApi,
              }),
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'landing.index',
              }),
              signUpWizardCompleted: isSignupWizardNotCompletedRedirect({
                notCompletedWizardRedirect: 'user.sign-up-wizard',
                userApi: dependencies.userApi,
              }),
            },
          },
          {
            component: createSections({
              components: [
                createLandingHero({
                  imageUrl: heroImageUrl,
                  signupRoute: 'auth.signup',
                }),
                createLandingMerits({
                  meritItems: getMeritItems,
                  signupRoute: 'auth.signup',
                }),
                createNewProductsList({
                  catalogApi: dependencies.catalogApi,
                  numberOfProducts: 5,
                  productRoute: 'positions.show',
                }),
                createLandingHowToUse({
                  signupRoute: 'auth.signup',
                  stepItems: getHowToUseStepItems,
                }),
                createLandingSearchByAttributeItems({
                  attributeName: 'skills',
                  catalogApi: dependencies.catalogApi,
                  tagSearchRoute: 'positions.list',
                }),
                createLandingFAQ({
                  faqItems: getFAQItems,
                }),
              ],
            }),
            name: 'landing.index',
            pageTitle: (t) => t('Landing:pageTitle'),
            path: '/home',
            validators: {
              isNotLoggedIn: isNotLoggedIn({
                loggedInRedirect: 'landing.mypage',
              }),
            },
          },
          {
            component: createLogin({
              alreadyVerifiedRoute: 'user.sign-up-wizard',
              authApi: dependencies.authApi,
              showSignupLink: true,
              userApi: dependencies.userApi,
            }),
            name: 'auth.login',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:loginPageTitle'),
            path: '/auth/login',
          },
          {
            component: createResetPasswordRequest({
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-request',
            navigationOptions: {
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Auth:resetPasswordRequestPageTitle'),
            parentBlockPath: 'auth.login',
            path: '/auth/reset-password-request',
          },
          {
            component: createResetPasswordSubmit({
              passwordValidateStrategy: utils.defaultPasswordValidateStrategy,
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-submit',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordSubmitPageTitle'),
            path: '/auth/reset-password-submit/:resetPasswordToken',
          },
          {
            component: createResetPasswordSuccess({
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-success',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordSuccessPageTitle'),
            path: '/auth/reset-password-success',
          },
          {
            component: createLogout({
              authApi: dependencies.authApi,
              logoutRedirect: '/home',
            }),
            name: 'auth.logout',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            path: '/auth/logout',
          },
          {
            component: createSignUp({
              alreadyVerifiedRoute: 'user.sign-up-wizard',
              authApi: dependencies.authApi,
              homeRoute: 'user.sign-up-wizard',
              passwordValidateStrategy: utils.defaultPasswordValidateStrategy,
              privacyPolicyUrl: `https://geekle.${domain}/privacy-policy`,
              userAgreementUrl: `https://geekle.${domain}/user-agreement`,
              userApi: dependencies.userApi,
            }),
            name: 'auth.signup',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:signUpPageTitleNavigation'),
            path: '/auth/sign-up',
          },
          {
            component: createVerifyEmailSuccess({
              userApi: dependencies.userApi,
              verifiedRoute: 'user.sign-up-wizard',
            }),
            name: 'auth.verify-email-success',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:verifyEmailSuccessPageTitle'),
            path: '/auth/verify-email-success/:verifyEmailToken',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
                sendVerificationEmail: false,
              }),
            },
          },
          {
            component: createWizard<GeekleInitialWizardFormData>({
              pages: [
                {
                  component: createWizardBaseInfo({}),
                  key: 'base-info',
                },
                {
                  component: createWizardCategorySelect({
                    catalogApi: dependencies.catalogApi,
                  }),
                  key: 'category-select',
                },
                {
                  component: createWizardCategoryExperience({
                    catalogApi: dependencies.catalogApi,
                  }),
                  key: 'category-experience',
                },
                {
                  component: createWizardAttributeItemsSelect({
                    attributeName: 'skills',
                    catalogApi: dependencies.catalogApi,
                  }),
                  estimatedSubPageCount: 3,
                  key: 'skill-select',
                },
                {
                  component: createWizardDesiredCompensation({}),
                  key: 'desired-compensation',
                },
                {
                  component: createGeekleInitialWizardSubmit({
                    userApi: dependencies.userApi,
                  }),
                  key: 'submit',
                },
              ],
              wizardCompleteRoute: 'landing.mypage',
            }),
            name: 'user.sign-up-wizard',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('User:signUpWizardPageTitle'),
            path: '/sign-up-wizard/:page',
            validators: {
              signUpWizardCompleted: isSignupWizardCompletedRedirect({
                completedWizardRedirect: 'landing.mypage',
                userApi: dependencies.userApi,
              }),
            },
          },
          {
            component: createDummy({ text: 'TODO: create user profile page' }),
            name: 'user.profile',
            pageTitle: (t) => t('Profile:profilePageTitle'),
            path: '/profile',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createProductSearch({
              catalogApi: dependencies.catalogApi,
              filterProductsRoute: 'positions.filter',
              numberOfProductsPerPage: 10,
              productRoute: 'positions.show',
            }),
            name: 'positions.list',
            pageTitle: (t) => t('Product:productListPageTitle'),
            path: '/positions',
          },
          {
            component: createProductFilter({
              attributeName: 'skills',
              catalogApi: dependencies.catalogApi,
              searchProductsRoute: 'positions.list',
            }),
            name: 'positions.filter',
            navigationOptions: {
              hideFooter: true,
              hideTopBarBorder: true,
              keepSearchParamsOnBack: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Product:productFilterPageTitle'),
            parentBlockPath: 'positions.list',
            path: '/positions/filter',
          },
          {
            component: createProductDetailsPageForDemand({
              catalogApi: dependencies.catalogApi,
              orderApi: dependencies.orderApi,
              organizationShowRoute: 'organization.show',
              userApi: dependencies.userApi,
            }),
            name: 'positions.show',
            pageTitle: (t) => t('Product:productDetailsPageTitle'),
            path: '/positions/:productId',
          },
          {
            component: createMyOrdersListPage({
              orderApi: dependencies.orderApi,
              productRoute: 'positions.show',
            }),
            name: 'job-applications.list',
            pageTitle: (t) => t('Order:myOrders.pageTitle'),
            path: '/job-applications',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createOrganizationDetailsPage({
              catalogApi: dependencies.catalogApi,
              organizationApi: dependencies.organizationApi,
              organizationListRoute: 'organization.positions.list',
            }),
            name: 'organization.show',
            pageTitle: (t) => t('Organization:detailsPageTitle'),
            path: '/organization/:organizationId',
            validators: {},
          },
          {
            component: createOrganizationJobPositionsListPage({
              catalogApi: dependencies.catalogApi,
              organizationApi: dependencies.organizationApi,
              organizationShowRoute: 'organization.show',
              productRoute: 'positions.show',
            }),
            name: 'organization.positions.list',
            pageTitle: (t) => t('Organization:positionsListPageTitle'),
            path: '/organization/:organizationId/positions',
            validators: {},
          },
          {
            component: createListTopicsForUserBlock({
              chatApi: dependencies.chatApi,
              chatShowRoute: 'chat.show',
              socketApi: dependencies.chatSocketApi,
            }),
            name: 'chat.topics.list',
            navigationOptions: {
              hideFooter: true,
            },
            pageTitle: (t) => t('Chat:listTopicsForUser.pageTitle'),
            path: '/messages/topics',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createListMessagesForTopicBlock({
              chatApi: dependencies.chatApi,
              organizationApi: dependencies.organizationApi,
              organizationShowRoute: 'organization.show',
              socketApi: dependencies.chatSocketApi,
            }),
            name: 'chat.show',
            navigationOptions: {
              hideFooter: true,
              topBarType: 'singleItem',
            },
            pageTitle: {
              fallback: (t) => t('Chat:listMessagesForTopic.pageTitle'),
              // eslint-disable-next-line @typescript-eslint/naming-convention
              fetch: async (blockProps, _t, updatePageTitle) => {
                const topic = await dependencies.chatApi.getTopic({
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  topicId: blockProps.params.topicId!,
                });

                if (topic) {
                  const [, ...filteredTopicNameParts] = topic.name.split('/');
                  const filteredTopicName = filteredTopicNameParts
                    .join('/')
                    .trim();

                  updatePageTitle(filteredTopicName);
                }
              },
            },
            parentBlockPath: 'chat.topics.list',
            path: '/messages/topics/:topicId',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createGroupedSettingsPage({
              changeEmailRoute: 'settings.change-email',
              changePasswordRoute: 'settings.change-password',
              deactivateAccountRoute: 'settings.deactivate-account',
              userApi: dependencies.userApi,
            }),
            name: 'settings',
            pageTitle: (t) => t('Settings:pageTitle'),
            path: '/settings',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangeEmail({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-email',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changeEmail.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-email',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createVerifyChangeEmailSuccess({
              userApi: dependencies.userApi,
              verifiedRoute: 'settings',
            }),
            name: 'settings.verify-change-email-success',
            navigationOptions: {
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Settings:verifyEmail.title'),
            path: '/settings/verify-change-email-success/:verifyChangeEmailToken',
          },
          {
            component: createChangePassword({
              cancelRoute: 'settings',
              forgotPasswordUrl: 'auth.reset-password-request',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-password',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changePassword.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-password',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createDeactivateAccount({
              successRoute: 'settings.deactivate-account-success',
              userApi: dependencies.userApi,
            }),
            name: 'settings.deactivate-account',
            pageTitle: (t) => t('Settings:deactivateAccount.title'),
            parentBlockPath: 'settings',
            path: '/settings/deactivate-account',
          },
          {
            component: createDeactivateSuccessAccount({
              successRoute: 'auth.logout',
            }),
            name: 'settings.deactivate-account-success',
            pageTitle: (t) => t('Settings:deactivateAccountSuccess.title'),
            parentBlockPath: 'settings',
            path: '/settings/deactivate-account-success',
          },
        ],
        error500Component: Error500,
        errorMessageI18nHandler: defaultErrorMessageI18nHandler,
        i18nOptions: {
          resources: merge(
            loadDefaultTranslations(),
            loadTranslationsFromYaml(translationOverrides)
          ),
        },
        logger: new log.FrontendLogger({
          appName: opts.appName ?? 'geekle-demand-app',
          // @ts-expect-error vite defines magic property
          env: import.meta.env.PROD ? 'production' : 'development',
        }),
        navigationComponent: createNavigation({
          headerElements: (isLoggedIn: boolean): NavigationHeaderElement => {
            if (isLoggedIn) {
              return {
                icons: [{ icon: 'chat_bubble_outline' }],
                links: [{ to: 'chat.topics.list' }],
                type: 'icons',
              };
            }

            return {
              buttons: [
                {
                  children: 'ログイン',
                },
                {
                  children: '無料登録',
                  fill: 'fill',
                },
              ],
              links: [
                {
                  to: 'auth.login',
                },
                {
                  to: 'auth.signup',
                },
              ],
              type: 'buttons',
            };
          },
          headerLogoElement: (onNavigate) => (
            <Link href="/" onNavigate={onNavigate}>
              <img src={logoUrl} alt="Geekle logo" />
            </Link>
          ),
          sideNavigationBlocks: [
            createSideNavigationItemUserInfo({
              toRoute: 'user.profile',
              userApi: dependencies.userApi,
            }),
            createSideNavigationItemDefault({
              icon: 'search',
              text: (t) => t('Geekle:sidebarSearchPositions'),
              toRoute: 'positions.list',
            }),
            createSideNavigationItemDefault({
              icon: 'front_hand',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarOrders'),
              toRoute: 'job-applications.list',
            }),
            createSideNavigationItemDefault({
              icon: 'person',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarProfile'),
              toRoute: 'user.profile',
            }),
            createSideNavigationItemDefault({
              icon: 'settings',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarSettings'),
              toRoute: 'settings',
            }),
            createSideNavigationItemDefault({
              icon: 'logout',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarLogout'),
              toRoute: 'auth.logout',
            }),
            createSideNavigationItemExternalUrl({
              href: `https://geekle-supply.${domain}`,
              icon: 'maps_home_work',
              requiresLogout: true,
              text: (t) => t('Geekle:sidebarToSupplyWebsite'),
            }),
            createSideNavigationItemExternalUrl({
              href: `https://geekle.${domain}/privacy-policy`,
              icon: 'privacy_tip',
              requiresLogout: true,
              text: (t) => t('Geekle:sidebarPrivacyPolicy'),
            }),
          ],
        }),
        notFound404Component: NotFound404,
        pageTitleConfiguration: {
          appName: 'Sample App',
        },
        screenConfiguration: {
          desktopCutoff: 950,
          enabledSizes: {
            bigDesktop: false,
            desktop: false,
            mobile: true,
          },
          mobileCutoff: 640,
        },
        theme: {
          configs: {
            timezone: 'Asia/Tokyo',
          },
        },
        toastConfiguration: {
          displayMs: 5000,
          toastComponent: ToastsDisplay,
        },
      },
      opts
    );
    this.dependencies = dependencies;
  }
}
